<template>
  <v-row>
    <v-col cols="12" class="py-0">
      <v-data-table
        :headers="headers"
        :items="data"
        :expanded.sync="expanded"
        hide-default-footer
        item-key="id"
        :items-per-page="sizes[size]"
        :show-expand="expandedHeaders.length > 0"
        :loading="loading"
        loading-text="Loading data from API...please wait"
        class="synapsa-table"
      >
        <template v-slot:item.rule="{ item }">
          {{ item.rule_id ? item.rule_id : "-" }}
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td
            :colspan="headers.length"
            class="expand-block"
            :class="data.indexOf(item) === data.length - 1 ? 'last' : ''"
          >
            <v-simple-table>
              <template v-slot:default>
                <tbody class="expanded">
                  <tr
                    v-for="(expandItem, key) in expandedHeaders"
                    :key="expandItem.value"
                    class="expand-tr"
                    :class="
                      data.indexOf(item) === data.length - 1 &&
                      key === expandedHeaders.length - 1
                        ? 'last'
                        : ''
                    "
                  >
                    <!--IP-->
                    <td
                      v-if="expandItem.value === 'ip_address'"
                      width="150"
                      class="pl-3"
                    >
                      IP:
                    </td>
                    <td v-if="expandItem.value === 'ip_address'">
                      {{ item.ip_address ? item.ip_address : "-" }}
                    </td>
                    <!--END IP-->

                    <!--RULE-->
                    <td
                      v-if="expandItem.value === 'rule'"
                      width="150"
                      class="pl-3"
                    >
                      Rule:
                    </td>
                    <td v-if="expandItem.value === 'rule'">
                      {{ item.rule ? item.rule : "-" }}
                    </td>
                    <!--END RULE-->

                    <!--LIFETIME-->
                    <td
                      v-if="expandItem.value === 'lifetime'"
                      width="150"
                      class="pl-3"
                    >
                      Lifetime:
                    </td>
                    <td v-if="expandItem.value === 'lifetime'">
                      {{ item.lifetime ? item.lifetime : "-" }}
                    </td>
                    <!--END LIFETIME-->
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>

        <!-- DELETE COLUMN-->
        <template v-slot:item.delete="{ item }">
          <v-menu
            v-permission="['write customaddress']"
            v-model="item.deleteMenu"
            :close-on-content-click="false"
            rounded
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn x-small icon color="bad-2" v-on="on" v-bind="attrs">
                <v-icon x-small>fas fa-trash-alt</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-list width="350">
                <v-list-item>
                  <v-list-item-content class="pb-0">
                    <v-list-item-title
                      >Are you sure you want to delete this data?
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-right mt-2">
                      <v-btn
                        rounded
                        text
                        color="tertiary"
                        small
                        class="text-capitalize"
                        @click="cancelDelete(item)"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        rounded
                        text
                        color="bad-2"
                        small
                        class="text-capitalize"
                        @click="deleteIp(item)"
                      >
                        Delete
                      </v-btn>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </template>
        <!-- END DELETE COLUMN-->
      </v-data-table>
    </v-col>
    <v-col
      v-if="data.length > 0"
      cols="6"
      class="py-0 px-0 synapsa-pagination mb-5"
    >
      <v-btn-toggle v-model="size" tile group class="results px-1">
        <v-btn x-small class="result" rounded> 5 </v-btn>

        <v-btn x-small class="result" rounded> 10 </v-btn>

        <v-btn x-small class="result" rounded> 25 </v-btn>

        <v-btn x-small class="result" rounded> 50 </v-btn>

        <v-btn x-small class="result" rounded> 100 </v-btn>

        <v-btn x-small class="result" rounded> 200 </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col
      v-if="data.length > 0"
      cols="6"
      class="text-right py-0 px-0 synapsa-pagination mb-5"
      :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
    >
      <v-pagination
        v-model="page"
        :length="pageTotalCount"
        :total-visible="7"
        circle
        class="d-inline-block pagination px-1 py-1"
        :class="page > 99 ? 'over100' : 'less100'"
      />
    </v-col>
  </v-row>
</template>

<script>
import permission from "@/directive/permission/permission.js";
import checkPermission from "@/utils/permissions";

export default {
  directives: { permission },
  props: {
    headers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    expandedHeaders: {
      type: Array,
      default: function () {
        return [];
      },
    },
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    tablePage: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tablePageTotalCount: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tableSize: {
      type: Number,
      default: function () {
        return 1;
      },
    },
  },
  data() {
    return {
      expanded: [],

      page: 1,
      pageTotalCount: 1,
      size: 1,

      sizes: [5, 10, 25, 50, 100, 200],
    };
  },
  computed: {
    keywordAddForm: {
      get() {
        return this.$store.state.settings.keywordAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "keywordAddForm",
          value: val,
        });
      },
    },
    idAddForm: {
      get() {
        return this.$store.state.settings.idAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "idAddForm",
          value: val,
        });
      },
    },
  },
  watch: {
    tablePage: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.page = newValue;
      },
    },
    tablePageTotalCount: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.pageTotalCount = newValue;
      },
    },
    tableSize: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) this.size = this.sizes.indexOf(newValue);
      },
    },
    page: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue !== this.tablePage) {
          this.$emit("change", { page: newValue, size: this.sizes[this.size] });
        }
      },
    },
    size: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (
          newValue !== undefined &&
          newValue !== this.sizes.indexOf(this.tableSize)
        ) {
          this.$emit("change", { page: this.page, size: this.sizes[newValue] });
        }
      },
    },
    expandedHeaders: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue.length === 0) {
          this.expanded = [];
        }
      },
    },
  },
  methods: {
    checkPermission,
    open(id) {
      console.log(id);
    },
    openEditForm(item) {
      console.log(item);
    },
    cancelDelete(item) {
      this.$emit("cancelDelete", item);
    },
    deleteIp(item) {
      this.$emit("deleteIp", item);
    },
  },
};
</script>

<style lang="scss" scoped></style>
